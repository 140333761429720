
<script lang="ts">
	import { defineComponent, onMounted, onBeforeUnmount, ref } from 'vue';

	export default defineComponent({
		props: {
            thumbnailUrl: String,
            videoUrl: String,
            alt: {
                type: String,
                required: false,
                default: 'Video Thumbnail',
            },
        },
		setup(props: any) {
			const isLargeScreen = ref(window.innerWidth >= 767);
			const lightboxOpen = ref(false);
			const videoMovil = ref(true)

			const openLightbox = () => {
				lightboxOpen.value = true;
			};

			const closeLightbox = () => {
				lightboxOpen.value = false;
			};

			const openVideoMovil = () => {
				videoMovil.value = false;
			};

			const handleResize = () => {
				isLargeScreen.value = window.innerWidth >= 767;
			};

			const handleKeyDown = (event: KeyboardEvent) => {
				if (event.key === 'Escape') {
					closeLightbox();
				}
			};

			onMounted(() => {
				window.addEventListener('resize', handleResize);
				window.addEventListener('click', handleClickOutside);
				document.addEventListener('keydown', handleKeyDown);
			});

			onBeforeUnmount(() => {
				window.removeEventListener('resize', handleResize);
				window.removeEventListener('click', handleClickOutside);
			});

			const handleClickOutside = (event: Event) => {
				const target = event.target as HTMLElement;
				const isOutsideLightbox = !target.closest('.lightbox');
				const isOutsideThumbnail = !target.closest('.lightbox-trigger');

				if (lightboxOpen.value && isOutsideLightbox && isOutsideThumbnail) {
					closeLightbox();
				}
			};

			onMounted(() => {
				window.addEventListener('click', handleClickOutside, { passive: true });
			});

			onBeforeUnmount(() => {
				window.removeEventListener('click', handleClickOutside);
			});

			return {
				isLargeScreen,
				lightboxOpen,
				openLightbox,
				closeLightbox,
				props,
				videoMovil,
				openVideoMovil,
			};
		},
	});
</script>

<template>
	<div class="">
		<div v-if="isLargeScreen"  class="lightbox-trigger flex flex-col items-center justify-between w-full">
			<div v-if="lightboxOpen" @click.stop="closeLightbox" class="lightbox  z-[51]">
				<iframe :src="props.videoUrl" frameborder="0" allowfullscreen class="lg:w-[60%] lg:h-[60%] md:w-[75%] md:h-[75%]"></iframe>
				<button @click.stop="closeLightbox" class="close-button focus:outline-none lg:top-[15%] lg:right-[17%] md:top-[5%] md:right-[05%] rounded-none px-3 font-bold bg-transparent hover:text-hwine text-white">X</button>
			</div>
			<img :src="props.thumbnailUrl" width="40" height="40" loading="lazy" :alt="props.alt" @click.passive="openLightbox" class="w-auto cursor-pointer" />
		</div>
		<div v-else class="flex items-center justify-center w-full h-auto">
			<div v-if="videoMovil" @click.passive="openVideoMovil">
				<img :src="props.thumbnailUrl" width="120" height="120" loading="lazy" :alt="props.alt" class="cursor-pointer w-auto" />
			</div>
			<iframe v-else
				:src="props.videoUrl"
				title="YouTube video player"
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowfullscreen
				loading="lazy"
				class="w-full"
				style="height: 280px;" 
			></iframe>
		</div>
	</div>
</template>

<style scoped>
	.lightbox {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
	}


	.close-button {
		border: none;
		cursor: pointer;
		font-size: 18px;
        font-weight: bold;
		position: absolute;
	}

	.lightbox-trigger {
		position: relative;
		
	}
</style>